/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



@font-face {
  font-family: 'Din';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/DIN-Black.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir.ttc');
}

body {
  --ion-font-family:  'Avenir'!important;
  font-family:  'Avenir' !important;
  line-height: 1;
}

h1, h2 {
  --ion-font-family:  'Din'!important;
  font-family:  'Din' !important;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
}

ion-button {
  --box-shadow: none;
}
ion-grid {
  padding: 0;
}

ion-item {
  ion-label {
    font-weight: bold;
  }
}

ion-accordion {
  background: rgba(255,255,255,0)!important;
  margin-bottom: 0.6rem;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: var(--ion-color-secondary);
}
.item-native {
  border-width: 0;
}

ion-tab-button {
  &.tab-selected {
    color: var(--ion-color-secondary);
    ion-icon {
      color: var(--ion-color-secondary);
    }
  }
}

ion-card-header {
  padding: 1rem 0 0 0;
}


.header-md::after, .footer-md::before {
  background-image: none!important;
}

.loading {
  --background: rgba(255,255,255,0.9);
  --spinner-color: var(--ion-color-danger);
  color: var(--ion-color-light);
  --backdrop-opacity: 1;

  ion-backdrop {
    background: rgba(255,255,255,1);
  }

  .loading-wrapper.sc-ion-loading-md {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }

}



.error-message {
  padding: 1rem;
  background: rgba(255,255,255,0.4);
  border-radius: 4px;
  margin: 0.6rem 0;
  text-align: center;
  color: var(--ion-color-dark);
  line-height: 1.2;
}

/* loading & splash */
.splash {
    background: var(--ion-color-primary);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
  
    ion-spinner {
      width: 60px;
      height: 60px;
    }
  
    @media only screen and (max-width: 600px){

    }
  
  }


  .login-background {
    justify-content: center;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    background-position: bottom center;
 
    height: 100%;
    width:100%;
  
    ion-grid, ion-row {
      height: 90%;
    }

    .logo-container {
      text-align: center;
      margin-bottom: 1rem;
    }

    form {
      margin: 0 1rem;
    }

    .codeBox-container {

      margin:1rem 0;

      .codeBox {
        border: 1px solid rgba(255,255,255,0.4);
        margin: 0.6rem;
        border-radius: 2px;
        text-align: center;
        padding: 0.6rem;
      }


    }

    .codeSuccess {
      font-size: 3rem;
    }
    
    
  }


  // Header
ion-header {

  .logo {
      max-height: 40px;
      transition: 0.2s;
      @media only screen and (max-width: 600px){
          max-width: 100px;
      }
  }


  ion-item {
      display: inline-block;
  }
  nav {
       display: inline-block;
  }
  .navItems {
      float: right;
      .navLinks {
          display: inline-block;
      }
  }
  

  .ion-padding {
      padding: 0!important;
  }

  .announcement {
    text-align: center;
  }
  .announcement-bar-link {
      cursor: pointer;
  }

  .active-link {
      --color: var(--ion-color-secondary);
  }

}


// Traditional navigation
.navHeader {
  padding: 1rem;
}
.navLinks {

  .link {
      --color: var(--ion-color-primary);
      --padding-end: 0.5em;
      --padding-start: 0.5em;
      &:hover {
          --color: var(--ion-color-light);
          //--background: rgba(var(--ion-color-dark-rgb), 0.1);
      }
  }

  

}


.icon-link {
  --padding-end: 0.5em;
  --padding-start: 0.5em;
}


// modal
ion-modal {
  --width: 100%;
  --height: 100%;
}


// Modal navigation
.modal-nav {
  padding: 3rem 2rem;

  .modal-nav-item {
      margin-bottom: 1rem;
      text-align: center;

      .modal-link {
      
          --color: var(--ion-color-dark);
          //--padding-end: 1em;
          //--padding-start: 1em;
          font-size: 1.2rem;
          &:hover {
              --color: var(--ion-color-primary);
              //--background: rgba(var(--ion-color-dark-rgb), 0.1);
          }
      }
  
      .modal-sub-items {
          padding: 0 0.5rem;
      
          .modal-sub-item {
              display: inline-block;
              @media only screen and (max-width: 600px){
                  display: block;
              }
          }
      }

      .button-disabled {
          opacity: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }

  }

  ion-icon {
    margin-left: 0.5rem;
  }

}

.modal-info {
  margin: 0 1.5rem;
  text-align: left;
  font-size: 0.9rem;
  color: rgba(var(--ion-color-dark-rgb), 0.5);
  padding: 1rem 0;
  line-height: 1.4;
  border-top: solid 1px rgba(var(--ion-color-dark-rgb), 0.2);

  a {
    color: rgba(var(--ion-color-dark-rgb), 0.5);
  }
}



  // cards

  ion-card {
    padding: 1rem;
    h1 {
      margin: 0;
    }

    .card-content-md {
      padding: 1rem 0;

      span {
        padding-right: 0.4rem;
        color: var(--ion-color-medium);
      }
    }

    ion-icon {
      margin-left: 0.6rem;
    }
  }



  .bigBg {
    //margin: 2rem;
    background: url('assets/images/off-road.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 600px){
      margin: 0;
      padding: 2rem;
      min-height: 100%;       
    }


    .bigNavContainer {
        margin: 4rem;
        padding: 2rem;
        background: url('assets/images/r+1-trees.jpg') no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;

        @media only screen and (max-width: 600px){
          margin: 0;
        }


      .bigNav {
        margin: 1rem 0;
        //padding: 2rem;
        font-family:  'Din' !important;
        font-size: 4rem;
        cursor: pointer;
        transition: 0.5s;
        color: #fff;
        

        @media only screen and (max-width: 600px){
          font-size: 2.4rem;
        }

        &:hover {
          color: rgba(255,255,255, 0.4);
        }
      }

    }

    
  }


  .contentWrapper {
    //margin: 2rem;
    //padding: 2rem;
    //background: rgba(var(--ion-color-light-rgb), 0.5);
    height: -webkit-fill-available;
    @media only screen and (max-width: 600px){
      margin: 0;
    }

  }

  .contentContainer {
    //margin-bottom: 4rem;
    padding: 4rem;
    background-size: cover!important;
    background-position: center center!important;
    min-height: calc(100vh - 86px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 600px){
      padding: 1rem 0.6rem;
      min-height: 100%;
      //margin-top: -20%;
    }




    .accordionWrapper {
      width: 100%;
      background: rgba(255,255,255,0.9);
      padding: 2rem;

      .gift-btn-container {
          margin: 2rem 0;
      }
    }

    .bikeWrapper {
      width: 100%;
      background: rgba(255,255,255,0.9);
      padding: 2rem;

      @media only screen and (max-width: 600px){
        padding: 1rem;
      }

      .giftImg {
        width: 100%;
      }

      .giftOptions {
        padding: 4rem;

        @media only screen and (max-width: 600px){
          padding: 0 0 3rem 0;

          h2 {
            text-align: center;
          } 
        }

      }

    }

    h1{
      color: var(--ion-color-light);
      font-size: 4rem;
      margin-bottom: 1rem;
      width: 100%;

      @media only screen and (max-width: 600px){
        font-size: 2.6rem;
      }

    }

    h2{
      color: var(--ion-color-light);
      font-size: 2rem;
      margin-bottom: 1rem;
      width: 100%;
    }

    a {
      color: var(--ion-color-secondary);
      font-size: 2.4rem;
      margin-bottom: 1rem;
      width: 100%;
      text-decoration: none;
      display: block;
      font-weight: bold;
    }
    

    form {
      width: 100%;
      background: rgba(255,255,255,0.9);
      padding: 2rem;
      //margin: 4rem;

      @media only screen and (max-width: 600px){
        margin: .8rem;
        padding: 1.4rem;
        ion-item {
          --inner-padding-end: 0;
          --padding-start: 0;
        }
      }


      ion-button {
        ion-icon {
          margin-right: 0.8rem;
        }
      }

      
    }

    
  }

  



  .spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .spinner-dark {
    background-color: var(--ion-color-dark);
  }
  
  .spinner-light {
    background-color: var(--ion-color-primary);
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }




  .cube1, .cube2 {
    background-color: var(--ion-color-dark);
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }
  
  .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
  }
  
  @keyframes sk-cubemove {
    25% { 
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% { 
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% { 
      transform: translateX(42px) translateY(42px) rotate(-180deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    } 75% { 
      transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    } 100% { 
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }
